.range-slider{
  $handle-width: 22px;
  $handle-height: 22px;
  $handle-color: #fff;
  
  $slider-height: 4px;
  $slider-color: darken($theme-frame-color, 20%);
  
  &__wrapper{
    position: relative;
    height: $handle-height;
    /* cancel &__control margin collaps */
    @include clearfix;
  }

  &__control{
    background: $slider-color;
    height: $slider-height;
    margin-top: ($handle-height - $slider-height)/2;

    .ui-slider-handle{
      display: block;
      @include position(absolute, 0 null null null);
      width: $handle-width;
      height: $handle-height;      
      background: $handle-color;
      cursor: pointer;
      -ms-touch-action: none;
      touch-action: none;
      border-radius: 50%;
      border: 1px solid $base-border-color;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

      &:last-of-type{
        margin-left: (-$handle-width);
      }
    }

    .ui-slider-range{
      position: absolute;
      top: 50%;
      margin-top: -($slider-height/2);
      height: $slider-height;
      background: $theme-main-color;
    }
  }
}