.benefits {

  &__item {
    display: table;
    font-size: .9rem;
  }

  &__ico {
    display: table-cell;
    vertical-align: middle;
    color: $benefits-ico-color;
    width: 64px;
    height: 64px;
    text-align: center;
    font-size: 0;
    border: 1px solid $base-border-color;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  &__img{
    display: inline-block;
    vertical-align: middle;
  }

  &__inner {
    display: table-cell;
    vertical-align: middle;
    padding-left: 0.7em;
  }

  &__title {
    margin-bottom: 0.2em;
    color: $benefits-title-color;
    font-size: 1.2em;
    line-height: 1;
    font-weight: bold;
  }

  &__desc {
    color: $benefits-desc-color;
  }
}