.user-panel {

  $badge-bg-color: $theme-secondary-color;
  $badge-text-color: #FFF;
  $badge-size: 20px;

  //Elements
  &__items {
    display: flex;
    justify-content: flex-end;
    margin: 0 -15px;
  }

  &__item {
    position: relative;
    flex-shrink: 0;
    text-align: center;
    margin: 0 15px;
  }

  &__icon {
    height: 28px;
    margin: 0 auto 5px;
    color: $theme-main-color;
    position: relative;

    &--wishlist{
      width: 30px;      
    }

    &--compare{
      width: 38px;
    }

    &--cart{
      width: 31px;
    }

  }

  &__link {
    font-size: .9rem;
  }

  &__counter {
    position: absolute;
    right: -50%;
    top: -50%;
    background: $badge-bg-color;
    width: $badge-size;
    height: $badge-size;
    line-height: $badge-size;
    border-radius: 50%;
    color: $badge-text-color;
    font-size: 11px;
    text-align: center;
  }

}