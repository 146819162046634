.toggle-view {

  @extend .btn-group;
  @extend .btn-group-sm;

  &__button {
    @extend .btn;
    @extend .btn-primary;

    &[disabled] {
      opacity:      1;
      border-color: $base-border-color;
      background:   #FFF;
      color:        $base-font-color-secondary;

      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        border-color: $base-border-color;
        background:   #FFF !important;
        color:        $base-font-color-secondary !important;
        z-index:      unset;
      }
    }
  }

  &__icon {
    width:  em(18);
    height: em(18);
  }

}