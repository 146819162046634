.product-fullinfo {

  &__item {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &__header {
    padding:       15px 0;
    margin-bottom: 1.2rem;
    border-bottom: 2px solid $theme-main-color;
  }

  &__title {
    font-size:   1.2rem;
    font-weight: bold;
    line-height: 1;
    color:       $widget-primary-title-color;
  }

}