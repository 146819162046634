.tabloid{
  $sidebar-width: 285px;
  $sidebar-indent: 30px;

  display: flex;
  flex-direction: column;

  @media (min-width: $screen-lg) {
    flex-direction: row;
  }

  &__banner{
    /* Slick carousel flex size fix  */
    width: 100%;
  }

  &__bargain{
    margin-top: 45px;
    flex-shrink: 0;

    @media (min-width: $screen-lg) {
      width: $sidebar-width;
      margin-top: 0;
      margin-left: $sidebar-indent;
    }
  }

}