.message {
  padding: 5px 10px;

  &--success {
    background-color: $message-success-color;
    border: 1px solid $message-success-border;
  }

  &--error {
    background-color: $message-error-color;
    border: 1px solid $message-error-border;
  }

  &--info {
    background-color: $message-info-color;
    border: 1px solid $message-info-border;
  }

  &__list {
    margin-left: 15px;
    list-style-type: disc;
  }

  //added "p" selector because it usually is included in validation errors JSON response
  &__item,
  &__list p,
  & p{
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
}