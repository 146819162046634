.main-properties {
  display: table;

  &__row {
    display: inline;
    &:not(:last-child) {
      &::after {
        content: " /";
      }
    }
  }
  &__name {
    display: inline-block;
    font-weight: 700;
    &::after {
      content: ":";
    }
  }
  &__value {
    display: inline-block;
  }
}