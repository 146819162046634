.start-page {
  display: flex;

  &__sidebar {
    width: $page-sidebar-width;
    flex-shrink: 0;
  }

  &__sidebar-item {
    &:not(:last-child) {
      padding-bottom: 40px;
    }
  }

  &__content {
    padding-top: 30px;
    width: 100%;

    @media (min-width: $screen-md-min) {
      width: calc(100% - #{$page-sidebar-width});
      padding-left: $page-sidebar-indent;
    }
  }

  &__row {
    padding-bottom: 45px;

    &--lg {
      padding-bottom: 65px;
    }
  }

}