.cart-frame {

  &__header {
    padding: 0 0 15px 0;
  }

  &__title {
    color: #000;
    font-size: 1.6rem
  }

  &__inner {
    border: 1px solid $base-border-color;
    padding: 20px;
  }
}