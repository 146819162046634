.product-photo {

  &__item {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: $product-photo-fluid-height;
    max-height: 100%;
    text-align: center;

    &--no-photo {
      @include link-empty;
    }

    &--xs,
    &--sm,
    &--md,
    &--lg {
      padding-bottom: 0px;
    }

    &--xs {
      width: $product-photo-fix-height-xs;
      height: $product-photo-fix-height-xs;
      max-height: $product-photo-fix-height-xs;

    }
    &--sm{
      height: $product-photo-fix-height-sm;
      max-height: $product-photo-fix-height-sm;
    }

    &--md{
      height: $product-photo-fix-height-md;
      max-height: $product-photo-fix-height-md;
    }

    &--lg {
      padding-bottom: 0;
      height: $product-photo-fix-height-lg;
      max-height: $product-photo-fix-height-lg;
    }

  }

  &__img {
    /* use important to override slick slide img display:block rule */
    display: inline-block !important;
    @include absolute-center-translate;
    max-height: inherit;
  }

  &__labels {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__label {
    display: block;
    background-color: $product-label-bg-default;
    width: 4.3em;
    height: 2.2em;
    line-height: 2.3em;
    font-size: 0.8em;
    text-align: center;
    color: #FFF;
    white-space: nowrap;

    &:not(:first-child) {
      margin-top: 1px;
    }

    &--hit {
      background-color: $product-label-bg-hit;
    }

    &--hot {
      background-color: $product-label-bg-hot;
    }

    &--action {
      background-color: $product-label-bg-action;
    }

    &--discount {
      background-color: $product-label-bg-discount;
    }
  }

  &__thumbs {
    font-size: 0;
    margin-top: 5px;
    margin-left: -10px;
  }

  &__thumb {
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    padding-top: 10px;
    width: percentage(1/$product-photo-thumbs-number);

    &-item {
      position: relative;
      border: 1px solid $product-photo-border-color;
      display: block;
      height: $product-photo-thumb-height;
      opacity: .5;

      &[data-product-photo-thumb-active] {
        opacity: 1;
      }
    }

    &-img {
      @include absolute-center-translate;
      display: block;
      padding: 5px;
      max-height: $product-photo-thumb-height;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__zoom {
    position: relative;
    top: -1px;
    left: 100%;
    z-index: 1000;

    display: inline-block;
    width: $product-photo-zoom-width;
    height: $product-photo-zoom-height;
    overflow: hidden;

    background: #FFF;
    border: 1px solid #C7C7C7;

    &:hover {
      display: none;
    }
  }

}