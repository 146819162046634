.widget-sidebar{
  $read-more-color: #000;
  $read-more-bg:    #fff;

  &__title{
    margin-bottom: 15px;
    font-size: 1.5rem;
    line-height: 1;
    color: #000;
  }

  &__inner{
    border: 1px solid $base-border-color;
    padding: 25px 20px;
  }

  &__item{

    &:not(:first-child){
      padding-top: 24px;
    }
  }

  &__read-more{
    display: block;
    width: 100%;
    padding: 10px 10px;
    border: 1px solid $base-border-color;
    border-top: 0;
    color: $read-more-color;
    background-color: $read-more-bg;
    text-decoration: none;
    text-align: center;
    font-size: .9em;
    font-weight: bold;

    &:hover{
      background: darken($read-more-bg, 2%);
    }
  }

}