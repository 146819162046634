.bargain {
  $arrow-height: 60px;
  $arrow-width: 27px;
  $arrow-bg: #f8f8f8;
  $svg-width: 13.5px;
  $svg-height: 30px;
  $price-list-height: 60px;

  position: relative;
  border: 1px solid $base-border-color;
  font-size: 1rem;

  /* equal height for slick items */
  .slick-track{
    display: flex !important;

    .slick-slide{
      height: auto !important;
    }
  }

  &__list{
    margin-left: -1px;
  }

  &__item {
    text-align: center;
    padding-bottom: $price-list-height;
    position: relative;
    border-left: 1px solid $base-border-color;
  }

  &__wrapper{
    padding: 15px 35px 0 35px;
  }

  &__ico {
    display: inline-block;
    width: 37px;
    height: 34px;
    margin-bottom: 10px;
    color: $theme-third-color;
  }

  &__label {
    font-size: .9rem;
    color: $base-font-color-secondary;
  }

  &__countdown {
    font-size: 1.4em;
    color: #000;
    display: inline-flex;
  }

  &__countdown-item{
    &:not(&--colon, :last-child) {
      margin-right: 5px;
    }

    &--colon{
      &::after{
        content: ':';
      }
    }
  }

  &__photo {
    padding: 20px 40px 10px 40px;
  }

  &__photo-item{
    width: 100%;
  }

  &__photo-img {
    margin: auto;
  }

  &__title{
    margin-bottom: 15px;
  }

  &__price-list {
    position: absolute;
    height: $price-list-height;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
  }

  &__price-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-top: 1px solid $base-border-color;

    &:not(:first-child){
      border-left: 1px solid $base-border-color;
    }
  }

  &__price-label{
    color: $base-font-color-secondary;
    font-size: .9em;
  }

  &__price-value{
    color: #000;
    font-size: 1.1em;
    font-weight: bold;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $arrow-width;
    height: $arrow-height;
    background-color: $arrow-bg;
    border: 1px solid $base-border-color;
    text-align: center;
    cursor: pointer;

    &:not(.slick-disabled):hover {
      background-color: darken($arrow-bg, 2%);
    }

    &--left {
      left: 0;
      border-left: 0;
    }

    &--right {
      right: 0;
      border-right: 0;
    }

    &.slick-disabled {
      opacity: .5;
      cursor: default;
    }
  }

  &__svg {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: $svg-width;
    height: $svg-height;
    color: #000;
  }
}