.sidebar-nav {
  font-size: 0.9rem;

  &__list {
    background-color: $theme-frame-color;
    padding:          20px;

    &:not(:first-child) {
      margin-top: 1px;
    }
  }

  &__title {
    color:         #000;
    font-size:     1.2em;
    margin-bottom: 1em;
    font-weight:   bold;
  }

  &__item {
    &:not(:first-child) {
      margin-top: .8em;
    }
  }
  &__item--active > &__link {
    text-decoration: none;
    color: $base-font-color;
  }

  &__link {
    @extend .link--main;
  }
  &__row {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
  &__inner {
    margin-top:  0.5em;
  }

  &__photo {
    display: block;
    position: relative;
    border: 1px solid $base-border-color;
    width: 100%;
    height: $bradns-image-height;
  }

  &__img {
    @include absolute-center-translate;
    display: block;
    max-height: $bradns-image-height;
    padding: 20px;
  }

}