.footer {
  $vertical-indent: 30px;

  display: flex;
  flex-wrap: wrap;
  color: $footer-text-color;

  &__sidebar {
    width: $page-sidebar-width;
    margin-right: $page-sidebar-indent;
    padding: $vertical-indent 0;
    flex-shrink: 0;
    font-size: .9em;
    color: $footer-title-color;

    &::before {
      @extend .hidden-xs;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 50%;
      background: $footer-sidebar-bg;
    }

  }
  &__sidebar-content {
    position: relative;
  }
  &__sidebar-item {
    &:not(:first-child) {
      margin-top: 15px;
    }

    &--indent-lg {
      &:not(:first-child) {
        margin-top: 35px;
      }
    }

    &--color-secondary {
      color: $footer-text-color;
    }
  }

  &__body {
    flex-grow: 1;
    padding: $vertical-indent 0 $vertical-indent 0;
    background: $footer-bg;
    position: relative;
  }

  &__title {
    font-weight: bold;
    color: $footer-title-color;
  }

  &__inner {
    margin-top: 15px;
  }

  &__row {
    display: flex;
    margin: 0 -15px;
    @media (min-width: $screen-sm) {
      justify-content: space-around;
    }
  }

  &__col {
    padding: 0 15px;
    max-width: 220px;
  }

  &__separator {
    width: 1px;
    border-right: 1px dashed #7c94e5;
  }

  &__items {

  }

  &__item {
    font-size: .9rem;
    margin-top: 10px;
  }

  &__link {
    color: $footer-link-color;
    text-decoration: underline;

    &:hover {
      color: $footer-link-hover-color;
      text-decoration: none;
    }
  }

}