.link {

  &--main {
    color: $base-main-link-color;
    text-decoration: $base-main-link-decor;

    &:hover {
      color: $base-main-link-hover-color;
      text-decoration: $base-main-link-decor-hover;
    }
  }

  &--second{
    color: $base-second-link-color;
    text-decoration: $base-second-link-decor;

    &:hover {
      color: $base-second-link-hover-color;
      text-decoration: $base-second-link-decor-hover;
    }
  }

  &--inherited {
    color: inherit;
  }

  &--js{
    text-decoration: none;
    border-bottom-style: dashed;
    border-bottom-width: 1px;

    &:hover {
      text-decoration: none;
    }
  }

  &--empty{
    &, &:hover {
      color: $base-font-color !important;
      text-decoration: none;
      cursor: default;
      pointer-events: none;
    }
  }

  &--arrow{
    position: relative;
    padding-right: 10px;

    &::after{
      content: '>';
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}