.product-sales {
  $indent: 1em;
  padding:    15px;
  border:     1px dashed $theme-third-color;
  background: #FFF;

  &__flex-container {
    display:         flex;
    flex-wrap:       wrap;
    justify-content: center;
    margin-top:      - $indent;
    margin-left:     - $indent;

    & > * {
      margin-top:  $indent;
      margin-left: $indent;
    }
  }
  &__imagebox {
    flex-basis: 90px;
  }
  &__info {
    flex-grow:  99;
    flex-basis: 180px;
  }
  &__timer {
    flex-shrink: 0;
    flex-grow:   1;
    flex-basis:  180px;
  }

  &__img {
  }
  &__title {
    font-weight: 700;
    color:       $theme-third-color;
  }
}