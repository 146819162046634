.product-cut {
  $overlay-indent: 10px;
  $overlay-border: 2px solid $theme-main-color;
  $overlay-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  position:  relative;
  font-size: 1rem;

  @media (min-width: $screen-md-min) {
    &:hover {
      &::before {
        content:       '';
        @include position(absolute, (-$overlay-indent) (-$overlay-indent) (-$overlay-indent) (-$overlay-indent));
        display:       block;
        border:        $overlay-border;
        box-shadow:    $overlay-shadow;
        border-bottom: 0;
      }
    }
  }
  /* Preventing hover overlay on slider items */
  .slick-slide &:hover,
  &--no-overlay:hover {
    &::before {
      display: none;
    }
  }

  &__row {
    &:not(:first-child) {
      margin-top: em(10);
    }
    &--flex-container {
      display:       flex;
      flex-wrap:     wrap;
      align-items:   center;
      margin-right:  -1em;
      margin-bottom: -1em;

      & > * {
        margin-bottom: 1em;
        margin-right:  1em;
      }
    }
  }

  &__main-info {
    position: relative;
  }
  &__extra-info {
    display:    none;
    @include position(absolute, 100% (-$overlay-indent) null (-$overlay-indent));
    z-index:    $z-index-s;
    border:     $overlay-border;
    box-shadow: $overlay-shadow;
    border-top: 0;
    padding:    0 $overlay-indent;
    background: #FFF;
  }
  &:hover &__extra-info {
    @media (min-width: $screen-md-min) {
      display: block;
    }
  }
  /* Preventing hover overlay on slider items */
  .slick-slide &:hover &__extra-info,
  &--no-overlay:hover &__extra-info {
    display: none;
  }

  /* Rating */
  &__photo {
  }

  /* Title */
  &__title {
    padding-top: 5px;

    &-link {
      @include link-main;
    }
  }

  /* Sale */
  &__sale {
    display: block;
    padding: 5px 0 0;
  }
  &__sale-item {
    &:not(:first-child) {
      margin-top: 3px;
    }
  }

  &__variants {
    padding-top: 12px;
  }

  &__price {
    margin-top:     10px;
    padding-bottom: 15px;
  }

  &__actions {
    display:     flex;
    flex-wrap:   wrap;
    padding:     12px 0;
    margin-left: -10px;
  }

  &__action-item {
    margin-left: 10px;
  }

  &__bonus {
    margin-top: 5px;
  }

  &__delete {
    @include position(absolute, 0 0 null null);
  }
  &__delete-icon {
    display: block;
    width:   20px;
    height:  20px;
    color:   #C5CDD8;
    fill:    #C5CDD8;
  }

  &__move {
    padding-bottom: 10px;
    margin-top:     -5px;
  }
  &__move-link {
    @include link-main;
    @include link-js;
  }

  /*  Short Description */
  &__desc {
    padding-bottom: 10px;
    font-size:      0.9em;
  }

  /*  Main Params */
  &__params {
    padding-bottom: 10px;
    font-size:      0.9em;
  }

  /*  Product is archived */
  &__archive {
    margin-top: 15px;
    display:    inline-block;
    padding:    5px 20px;
    background: $theme-secondary-color;
    color:      #000;
    font-size:  1.2rem;
  }

  /* Rating */
  &__rating {
    display: block;
  }
}