.btn {
  text-decoration: none;

  & > * {
    display:        inline-block;
    vertical-align: middle;
    margin-right:   em(2px);
    margin-left:    em(2px);
  }

  &--main {
    /* cancel default bootstrap blue outline */
    outline:    0 !important;
    /* cancel default bootstrap border */
    border:     0;
    background: $theme-main-color;
    color:      $btn-main-color;

    &:hover,
    &:focus {
      background: $btn-main-bg-hover;
      color:      $btn-main-color-hover;
    }
  }

  &__icon {
    display:  block;
    position: relative;

    &--search {
      width:  rem(17px);
      height: rem(20px);
    }

  }
}
.btn-inverse {
  @extend .btn;
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: $theme-third-color;
    color:            #FFF;
  }
}
.btn-order {
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-lg;
  }
}
.btn-white {
  @extend .btn;
  @extend .btn-default;
  background: #FFF;

  &--active {
    background: #F2F6F9;
  }

  &__ico {
    display:  inline-block;
    fill:     $theme-main-color;
    color:    $theme-main-color;
    position: relative;

    &--wishlist {
      width:  22px;
      height: 21px;
    }

    &--compare {
      width:  28px;
      height: 20px;
    }
  }
  &--active &__ico {
    fill:  $base-font-color;
    color: $base-font-color;
  }

}
.btn-light {
  &,
  &:hover,
  &:active,
  &:focus {
    color:      #BA9659;
    background: #FFF8DD !important;
    border:     1px solid #EEDBB2 !important;
  }
}
.btn-accent {
  @extend .btn;
  @extend .btn-primary;
  background:   $theme-third-color;
  border-color: $theme-third-color;

  &:hover,
  &:active,
  &:focus {
    background:   darken($theme-third-color, 5%) !important;
    border-color: $theme-third-color !important;
  }
}