.cat-table{
  padding-top: 40px;

  &__title{
    font-size: 1.4rem;
    padding-bottom: 0.5em;

    h1{
      display: inline;
    }
  }

  &__table{
    width: 100%;
    border: 1px solid $base-border-color;
    border-top: 2px solid $theme-main-color;
    border-collapse: collapse;

    th{
      border-bottom: 1px solid $base-border-color;
      padding: 15px;
      background-color: $theme-frame-color;
      color: #000;
      font-weight: 700;
    }

    td{
      padding: 15px 0 0 15px;
      vertical-align: bottom;
    }

    td+td{
      padding-right: 15px;
      padding-left: 0;
    }
  }

  &__cell-inner{
    padding-bottom: 15px;
    border-bottom: 1px dashed $base-border-color;
  }

  &__table tr:last-child &__cell-inner{
    border-bottom-color: transparent;
  }

  &__cell-title{
    text-align: left;
  }

  &__cell-price{
    text-align: right;
  }
}