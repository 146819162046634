.product-snippet {
  $cols-vertical-indent: 1em;
  $cols-horizontal-indent: 1em;

  display:        flex;
  flex-direction: column;
  align-items:    center;

  margin-bottom:  - $cols-vertical-indent;
  margin-right:   - $cols-horizontal-indent;

  & > * {
    padding-bottom: $cols-vertical-indent;
    padding-right:  $cols-horizontal-indent;
  }

  &__photo {
    flex-shrink: 0;
    width:       75%;
  }
  &__body {
    flex-grow:   1;

    display:     flex;
    flex-wrap:   wrap;

    margin-top:  - $cols-vertical-indent;
    margin-left: - $cols-horizontal-indent;

    & > * {
      margin-top:  $cols-vertical-indent;
      margin-left: $cols-horizontal-indent;
    }
  }
  @media (min-width: $screen-sm-min) {
    flex-direction: row;
    align-items:    flex-start;

    &__photo {
      width: 25%;
    }
  }

  &__info {
    flex-grow:  99;
    flex-basis: em(450px);
  }
  &__purchase {
    flex-grow:   1;
    flex-shrink: 0;
  }

  &__row {
    &:not(:first-child) {
      margin-top: 1em;
    }
    &--sm:not(:first-child) {
      margin-top: 0.5em;
    }
    &--lg:not(:first-child) {
      margin-top: 1.4em;
    }

    &--flex-container {
      $bottom-indent: 0.75em;
      $right-indent: 0.5em;

      display:       flex;

      margin-bottom: -$bottom-indent;
      margin-right:  -$right-indent;

      & > * {
        margin-bottom: $bottom-indent;
        margin-right:  $right-indent;
      }
    }
    &--align-right {
      text-align: right;
    }
  }

  &__title {
    font-size: 1.2em;
  }

  &__sale {
    color: $attention-color;
  }
  &__minor-text {
    color: $base-font-color-secondary;
  }
}