.accordion-tabs {
  $base-border-color: $base-border-color;
  $base-background-color: #f8f8f8;
  $base-spacing: 30px !default;
  $dark-gray: #333 !default;
  $medium-screen: $screen-sm-min;
  $tab-link-color: $dark-gray;
  $tab-border: 1px solid $base-border-color;
  $tab-active-background: #fff;
  $tab-inactive-color: $base-background-color;
  $tab-inactive-hover-color: #c0c0c0;
  $tab-mode: $medium-screen;
  $gutter: 2em;

  @include clearfix;

  &__item {
    list-style: none;

    @media (min-width: $screen-sm-min){
      display: inline;
    }
  }

  &__link {
    background-color: $tab-inactive-color;
    border: $tab-border;
    border-bottom: 0;
    margin-bottom: -1px;
    color: $tab-link-color;
    display: block;
    padding: ($base-spacing / 2) $gutter;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;

    @media (min-width: $screen-sm-min){
      display: inline-block;
    }

    &:focus {
      outline: none;
    }
  }
  &__item:first-child > &__link.js-init-active,
  &__link.js-active {
    position: relative;
    background-color: $tab-active-background;

    &::before {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      content: '';
      height: 3px;
      background-color: $theme-main-color;
    }


    @media (min-width: $screen-sm-min){
      border-bottom: 1px solid #fff;
    }
  }

  &__content {
    width: 100%;
    padding: 30px 0;

    @media (min-width: $screen-sm-min){
      border-top: $tab-border;
      float: left;
    }
  }
  &__item:not(:first-child) > &__content{
    display: none;
  }
}