.header {border-top: 3px solid $header-top-line-color;
  background: #fff;

  &__container {
    //extending bootstrap container
    @extend .container-fluid;
    max-width: $site-max-width;
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;

    margin: 0 -15px;
    /* Flex elements */
    & > * {
      margin: 0 15px;
    }

  }

  &__hamburger {
    text-align: left;
    width: 13%;
    @media (min-width: $screen-sm-min){
      width: auto;
      flex-shrink: 0;
    }
  }

  &__logo {
    max-width: $page-sidebar-width;
    font-size: 0;
    width: 67%;
    text-align: center;
    @media (min-width: $screen-sm-min){
      width: auto;
      flex-shrink: 0;
      text-align: left;
    }
    @media (min-width: $screen-md-min) {
      flex-shrink: 0;
    }
  }

  &__center {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    align-items: center;

    /* Flex elements */
    & > * {
      margin: 0 15px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      display: block;

      /* Flex elements */
      & > * {
        margin: 0;
        &:not(:first-child){
          padding-top: 10px;
        }
      }
    }
  }

  &__nav {
    flex-basis: 50%;
  }

  &__contacts {
    flex-basis: 50%;
  }

  &__delimiter {
    align-self: stretch;
    width: 1px;
    border-right: 1px dashed $header-delimiter-color;
  }

  &__right {
    width: 20%;
    @media (min-width: $screen-sm-min){
      width: auto;
      flex-shrink: 0;
    }
  }

}