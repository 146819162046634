.site-info {
  color: $site-info-color;
  font-size: .9rem;

  &__group{
    display: flex;

    &:not(:first-child){
      padding-top: 10px;
    }
  }

  &__aside {
    padding-right: 5px;
  }

  &__inner{
    margin-left: -10px;
    margin-top: -5px;
  }

  &__item{
    white-space: nowrap;
    display: inline-block;
    padding-left: 10px;
    padding-top: 5px;

    &--phone {
      font-size: 1rem;
      font-weight: bold;
      color: inherit;
      text-decoration: none;
    }
  }

  &__icon {
    position: relative;    
    color: $site-info-ico-color;

    &--phone{
      top: 2px;
      width: 13px;
      height: 13px;
    }

    &--clock{
      width: 16px;
      height: 16px;
    }
  }

}